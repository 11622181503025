import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './licenseMobile.module.scss'

const TransporterMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      dajiangImage: file(relativePath: { eq: "license/dajiang_license.png" }) {
        ...licenceImg
      }
      qushengImage: file(relativePath: { eq: "license/qusheng_license.png" }) {
        ...licenceImg
      }
      licenseMobile: file(relativePath: { eq: "license/license_h5_bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Img
        className={styles['license-bg-h5']}
        fluid={data.licenseMobile.childImageSharp.fluid}
        alt="mobile"
      />
      {/* </div> */}
      <div className={styles['licence']}>
        <Img
          className={styles['qusheng']}
          fluid={data.qushengImage.childImageSharp.fluid}
        />
        <Img
          className={styles['dajiang']}
          fluid={data.dajiangImage.childImageSharp.fluid}
        />
      </div>
    </>
  )
}

export default TransporterMobile
