import React from 'react'
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import LicenseMobile from 'src/components/LicenseMobile'
import styles from './license.module.scss'

export const licenceImg = graphql`
  fragment licenceImg on File {
    childImageSharp {
      fluid(maxWidth: 560, maxHeight: 396) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const query = graphql`
  query LicencePageQuery {
    dajiangImage: file(relativePath: { eq: "license/dajiang_license.png" }) {
      ...licenceImg
    }
    qushengImage: file(relativePath: { eq: "license/qusheng_license.png" }) {
      ...licenceImg
    }
    licenceBgImage: file(relativePath: { eq: "license/license_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    licenceBgh5Image: file(relativePath: { eq: "license/license_h5_bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Contact = ({ data }) => (
  <Layout mobile={<LicenseMobile />}>
    <SEO title={`营业执照`} />
    <div className={styles['licence-box']}>
      <Img className={styles['licence-bg']} fluid={data.licenceBgImage.childImageSharp.fluid} />
      <Img className={styles['license-bg-h5']} style={{ position: 'static' }} fluid={data.licenceBgh5Image.childImageSharp.fluid}/>
      <div className={styles['licence']}>
        <Img className={styles['qusheng']} fluid={data.qushengImage.childImageSharp.fluid} />
        <Img className={styles['dajiang']} fluid={data.dajiangImage.childImageSharp.fluid} />
      </div>
    </div>
  </Layout>
)

export default Contact
